<template>
  <div class="order">
    <div v-if="state.unpayOrder.length > 0" class="order-unpay">
      <div class="order-unpay__header">
        <span class="line"></span>
        <span class="title">待支付</span>
      </div>
      <div class="order-unpay__body">
        <div v-for="item in state.unpayOrder" :key="item.id">
          <div class="code-number">
            <span>订单编号：{{ item.sn }}</span>
            <span
              >下单时间：{{
                $filters.fmtDate(item.createDate, "yyyy-MM-dd") || "暂无"
              }}</span
            >
          </div>
          <h3 class="course-title">{{ item.course.courseName }}</h3>
          <div class="course-detail">
            <img :src="item.course.courseCover" alt="" />
            <span>¥{{ item.amount }}</span>
          </div>
          <div class="course-submit">
            <a-button class="pay-btn" type="primary" @click="payOrder(item)"
              >立即支付</a-button
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="state.finishOrder.length > 0" class="order-unpay">
      <div class="order-unpay__header">
        <span class="line"></span>
        <span class="title normal">已完成</span>
      </div>
      <div class="order-unpay__body">
        <div v-for="item in state.finishOrder" :key="item.id">
          <div class="code-number">
            <span>订单编号：{{ item.sn }}</span>
            <span
              >下单时间：{{
                $filters.fmtDate(item.createDate, "yyyy-MM-dd") || "暂无"
              }}</span
            >
          </div>
          <h3 class="course-title">{{ item.course.courseName }}</h3>
          <div class="course-detail">
            <img :src="item.course.courseCover" alt="" />
            <span>¥{{ item.amount }}</span>
          </div>
          <div class="course-submit">
            <a-button class="pay-btn" type="primary" @click="studyVideo(item)"
              >立即学习</a-button
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="state.cancelOrder.length > 0" class="order-unpay">
      <div class="order-unpay__header">
        <span class="line"></span>
        <span class="title normal">已取消</span>
      </div>
      <div class="order-unpay__body">
        <div v-for="item in state.cancelOrder" :key="item.id">
          <div class="code-number">
            <span>订单编号：{{ item.sn }}</span>
            <span
              >下单时间：{{
                $filters.fmtDate(item.createDate, "yyyy-MM-dd") || "暂无"
              }}</span
            >
          </div>
          <h3 class="course-title">{{ item.course.courseName }}</h3>
          <div class="course-detail">
            <img :src="item.course.courseCover" alt="" />
            <span>¥{{ item.amount }}</span>
          </div>
          <div class="course-submit">
            <a-button class="pay-btn" @click="seeMore(item)">看看其他</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, inject } from "vue";
import { queryOrderList } from "@/api/order";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const state = reactive({
  unpayOrder: [],
  cancelOrder: [],
  finishOrder: [],
});

// 立即支付
const payOrder = (rowItem) => {
  router.push({
    path: "/payByQrcode",
    query: {
      id: rowItem.course.id,
      payType: rowItem.payType,
      orderId: rowItem.id,
      sn: rowItem.sn,
    },
  });
};

// 看看其他
const seeMore = () => {
  router.push({
    path: "/home",
  });
};

// 立即学习
const studyVideo = (rowItem) => {
  router.push({
    path: "/detail",
    query: {
      id: rowItem.course.id,
      active: 2,
    },
  });
};

onMounted(() => {
  state.unpayOrder = [];
  state.cancelOrder = [];
  state.finishOrder = [];
  queryOrderList({
    pageNum: 1,
    pageSize: 100000,
  }).then((res) => {
    res.data.list.forEach((item) => {
      switch (item.status) {
        case 1:
          state.finishOrder.push(item);
          break;
        case 100:
          state.cancelOrder.push(item);
          break;
        default:
          state.unpayOrder.push(item);
      }
    });
  });
});
</script>

<style lang="less" scoped>
.order {
  width: 100%;
}
.order-unpay {
  width: 100%;
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  &__header {
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    .line {
      width: 5px;
      height: 18px;
      border-radius: 2px;
      background: #ffcc00;
      margin-right: 6px;
      margin-top: -2px;
    }
    .title {
      font-size: 18px;
      font-weight: 600;
      color: #f1943e;
      &.normal {
        color: #333;
      }
    }
  }
  &__body {
    // padding-top: 15px;
    .code-number {
      margin-top: 15px;
      color: #717171;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .course-title {
      font-size: 16px;
      font-weight: 600;
      margin-top: 12px;
    }
    .course-detail {
      margin-top: 12px;
      display: flex;
      align-items: end;
      justify-content: space-between;
      img {
        width: 200px;
      }
      span {
        font-size: 22px;
        font-weight: 600;
        margin-right: 10px;
      }
    }
    .course-submit {
      margin-top: 10px;
      text-align: right;
    }
  }
}
</style>
