import Login from "@/view/login/Mobile";
import Home from "@/view/home";
import AboutUs from "@/view/aboutUs";
import Subject from "@/view/subject";
import Detail from "@/view/detail";
import Video from "@/view/video";
import Personal from "@/view/personal";
import Pay from "@/view/pay";
import PayByQrcode from "@/view/pay/Qrcode.vue";
import Bargain from "@/view/bargain";

const routes = [
  { path: "/", redirect: "/home", show: true },
  { path: "/home", component: Home, name: "首页", show: true },
  { path: "/detail", component: Detail, name: "课程详情", show: false },
  { path: "/video", component: Video, name: "视频播放", show: false },
  { path: "/pay", component: Pay, name: "课程支付", show: false },
  {
    path: "/payByQrcode",
    component: PayByQrcode,
    name: "支付详情",
    show: false,
  },
  { path: "/subject", component: Subject, name: "题库", show: true },
  { path: "/aboutus", component: AboutUs, name: "关于我们", show: true },
  {
    path: "/login",
    component: Login,
    name: "登录",
    show: true,
    isLogin: false,
  },
  {
    path: "/personal",
    component: Personal,
    name: "个人中心",
    show: true,
    isLogin: true,
  },
  { path: "/bargain", component: Bargain, name: "订单砍价", show: false },
];

export default routes;
