<template>
  <div class="login">
    <img
      class="top"
      src="//static.yanzhishi.cn/images/wechat/login.png"
      mode="widthFix"
      alt="backgrond"
    />
    <div class="content">
      <img
        class="logo"
        src="//static.yanzhishi.cn/images/pc/2023/logo.png"
        alt="logo"
      />
      <div class="input-box">
        <div class="input-text">
          <img
            src="//static.yanzhishi.cn/images/wechat/entry/phone.png"
            mode="widthFix"
          />
          <a-input
            v-model:value="state.phone"
            type="number"
            placeholder="请输入手机号"
            placeholder-style="color:#575757;"
          />
        </div>
        <div class="input-text">
          <img
            src="//static.yanzhishi.cn/images/wechat/entry/lock.png"
            mode="widthFix"
          />
          <a-input
            v-model:value="state.verify"
            type="number"
            placeholder="请输入验证码"
            placeholder-style="color:#575757;"
          />
          <div v-if="state.showTime" class="time">{{ state.time }}S</div>
          <span v-else class="verify" @click="showVerity">获取验证码</span>
        </div>
      </div>
      <div
        :class="[
          'login-btn',
          state.verify.length > 0 && state.finishSend && 'active',
        ]"
        @click="loginUser"
      >
        立即登录
      </div>
      <!-- <div class="agreement">
        登录即表示同意<span @click="viewWord('register')">用户注册协议、</span
        ><span @click="viewWord('privacy')">隐私政策</span
        >。若您的手机号未注册，将为你直接注册研芝士账号
      </div> -->
    </div>
    <a-modal
      wrapClassName="smart-modal"
      v-model:visible="state.visible"
      title="智能验证"
      :footer="null"
      @ok="handleOk"
    >
      <div id="sc" class="smart"></div>
    </a-modal>
  </div>
</template>

<script setup>
import { message } from "ant-design-vue";
import { onUnmounted, reactive, nextTick, onMounted } from "vue";
import { getAliSms, bindUserPhone } from "@/api/login";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const state = reactive({
  phone: "",
  verify: "",
  showTime: false,
  time: 60,
  timer: null,
  finishSend: false,
  visible: false,
  showSmart: false,
  unionid: "",
});

const showVerity = () => {
  if (!/^[0-9]{11}$/.test(state.phone.trim())) {
    message.error("手机号格式错误");
    return;
  }
  state.visible = true;
  nextTick(() => {
    if (!window.ic) {
      createAWSC();
    } else {
      window.ic.reset();
    }
  });
};

const createAWSC = () => {
  const AWSC = window.AWSC;
  AWSC.use("ic", function (sta, module) {
    // 初始化
    window.ic = module.init({
      // 应用类型标识。它和使用场景标识（scene字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
      appkey: "FFFF0N0000000000B6C5",
      // 使用场景标识。它和应用类型标识（appkey字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的scene值，请务必正确填写。
      scene: "ic_login_h5",
      // 声明智能验证需要渲染的目标元素ID。
      renderTo: "sc",
      // 验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
      // default_txt: "获取验证码",
      success: function (data) {
        getAliSms({
          sessionId: data.sessionId,
          sig: data.sig,
          token: data.token,
          mobile: state.phone,
        }).then((res) => {
          console.log("getSms", res);
          if (res.code === 200) {
            state.finishSend = true;
            state.visible = false;
            state.showTime = true;
            state.time = 60;
            state.timer = setInterval(() => {
              if (state.time === 0) {
                state.showTime = false;
                clearInterval(state.timer);
                state.timer = null;
              } else {
                state.time--;
              }
            }, 1000);
          } else {
            message.error(res.msg);
            window.ic.reset();
          }
        });
      },
      // 验证失败时触发该回调参数
      fail: function (failCode) {
        console.log(failCode);
      },
      // 验证码加载异常时触发该回调参数
      error: function (errorCode) {
        console.log(errorCode);
      },
    });
  });
};

const loginUser = () => {
  bindUserPhone({
    mobile: state.phone,
    smsCode: state.verify,
  }).then((res) => {
    console.log("bindUserPhone", res);
    router.push({
      path: "/pay",
      query: {
        id: route.query.id,
      },
    });
  });
};

onUnmounted(() => {
  if (state.timer) clearInterval(state.timer);
});

onMounted(async () => {
  state.unionid = route.query.unionid;
  if (!state.unionid) {
    router.push({
      path: "/detail",
      query: {
        id: route.query.id,
      },
    });
  }
});
</script>

<style lang="less" scoped>
.login {
  position: relative;
  text-align: center;
  .content {
    position: absolute;
    width: 88%;
    left: 6%;
    top: 136px;
  }
  .top {
    width: 100vw;
  }
  .logo {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 3px solid #f6c144;
  }
  p {
    margin-top: 20px;
  }
  .btn {
    margin-top: 40px;
    background-color: #fddb00;
    border-radius: 6px;
    &::after {
      border: none;
    }
  }
  .input-box {
    margin-top: 80px;
  }
  .input-text {
    display: flex;
    align-items: center;
    background-color: #fafafa;
    padding-left: 10px;
    height: 45px;
    border-radius: 25px;
    margin-top: 20px;
    img {
      width: 15px;
      flex-shrink: 0;
      margin: 0 20px;
    }
    input {
      flex: 1;
      height: 45px;
      text-align: left;
      color: #575757;
      border: none;
      background-color: transparent;
      padding: 0;
      &:focus-visible,
      &:focus {
        outline-color: transparent;
        padding: 0;
        box-shadow: none;
      }
    }
    .verify {
      color: #575757;
      margin: 0 8px;
      padding-right: 10px;
    }
  }

  .smart {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    margin: 0 !important;
  }
  .login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #5b5b5b;
    background-color: #fafafa;
    height: 46px;
    border-radius: 23px;
    margin-top: 30px;
    &.active {
      background-color: #fddb00;
      color: #555;
    }
  }
  .agreement {
    font-size: 12px;
    color: #979797;
    padding: 15px 10px;
    span {
      color: #f6c144;
    }
  }
  .time {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fddb00;
    width: 84px;
    height: 45px;
    border-radius: 25px;
    color: #555;
  }
}
</style>

<style lang="less">
.smart-modal {
  .smart {
    display: flex;
    justify-content: center;
    margin: 0 !important;
  }
  .ant-modal {
    top: 50%;
    margin-top: -100px;
    max-width: calc(100vw - 46px);
  }
}
</style>
