import request from '@/utils/axios';
const basicAuthorization = 'Basic ' + btoa('web_app:spring-microservice-exam-secret')

export function getLoginImg(params) {
  return request({
    url: '/api/user/pc/login/get/code',
    method: 'post',
    params,
    headers: {
      Authorization: basicAuthorization
    }
  })
}

export function scanLoginResult(params) {
  return request({
    url: '/api/auth/wx/token',
    method: 'post',
    params,
    headers: {
      Authorization: basicAuthorization
    }
  })
}

export function getUserInfo(params) {
  return request({
    url: '/api/user/pc/user/detail',
    method: 'get',
    params
  })
}

export function getVerityCode(params) {
  return request({
    url: '/api/user/wx/login/get/sms',
    method: 'get',
    params
  })
}

export function phoneLogin(params) {
  return request({
    url: '/api/auth/wx/token?grant_type=wx&scope=read&code=' + params.code,
    method: "post",
  })
}

export function getAliSms(params) {
  return request({
    url: '/api/user/wx/login/afs/get/sms',
    method: 'get',
    params
  })
}

export function bindUserPhone(params) {
  return request({
    url: "/api/user/wx/user/bind/phone",
    method: 'post',
    data: params
  })
}

export function getVerityCodeByPc(params) {
  return request({
    url: '/api/user/pc/login/get/sms',
    method: 'get',
    params
  })
}

export function bindUserPhoneByPc(params) {
  return request({
    url: "/api/user/pc/user/bind/phone",
    method: 'post',
    data: params
  })
}