<template>
  <div>
    <div class="detail-header">
      <div class="common-container header-container">
        <div class="detail-header__left">
          <img :src="course.courseCover" alt="课程封面" />
        </div>
        <div class="detail-header__right">
          <h3>{{ course.courseName }}</h3>
          <p>
            有效期至：{{
              $filters.fmtDate(course.endTime, "yyyy-MM-dd") || "暂无"
            }}
          </p>
          <p>课时总数：{{ course.totalLesson || 0 }}</p>
          <div class="pay">
            <div class="left">
              <p class="pay-history-price">
                原价：¥<span>{{ course.underlinedPrice }}</span>
              </p>
              <p class="pay-price">
                ¥<span>{{ course.commodityPrice }}</span>
              </p>
            </div>
            <a-button
              class="pay-btn"
              type="primary"
              @click="emit('toPay')"
              :disabled="course.own"
              >{{ course.own ? "已报名" : "立即报名" }}</a-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="common-body">
      <div class="common-container">
        <div class="detail">
          <div class="left">
            <!-- <a-tabs class="course-tabs" v-model:activeKey="state.activeKey">
              <a-tab-pane
                v-for="item in state.detailCategory"
                :key="item.id"
                :tab="item.name"
              >
              </a-tab-pane>
            </a-tabs> -->
            <ul class="course-tabs">
              <li v-for="item in state.detailCategory" :key="item.id" :class="{ active: state.activeKey == item.id }" @click="state.activeKey = item.id">{{ item.name }}</li>
            </ul>
            <div v-show="state.activeKey == 1" class="info">
              <img :src="course.courseDetail" alt="" />
            </div>
            <div v-show="state.activeKey == 2" class="tabs-content">
              <div class="tabs-content-title">课表目录</div>
              <Directory ref="DirectoryRef" @view="viewVideo"></Directory>
            </div>
            <div v-show="state.activeKey == 3" class="tabs-content">
              <div class="tabs-content-title">课程评价</div>
              <CommentDetail :comments="comments"></CommentDetail>
            </div>
          </div>
          <div class="right">
            <a-card class="card" v-for="user in course.userVos" :key="user.id">
              <div class="title">
                <img
                  :src="
                    user.courseAvatar ||
                    '//static.yanzhishi.cn/images/wechat/user.jpg'
                  "
                  alt="头像"
                />
                <p>{{ user.nickName }}</p>
              </div>
              <div class="content">
                {{ user.brief || "暂无简介" }}
              </div>
            </a-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, KeepAlive } from "vue";
import { useRouter, useRoute } from "vue-router";
import Directory from "@/components/Directory";
import CommentDetail from "@/components/CommentDetail";
const router = useRouter();
const route = useRoute();

defineProps(["course", "comments"]);
const emit = defineEmits(["toPay"]);

const state = reactive({
  detailCategory: [
    {
      id: 1,
      name: "课程介绍",
    },
    {
      id: 2,
      name: "课表目录",
    },
    {
      id: 3,
      name: "课程评价",
    },
  ],
  activeKey: route.query.active ? Number(route.query.active) : 1,
});

const viewVideo = ({ lesson, course }) => {
  let routeUrl = router.resolve({
    path: `/video`,
    query: {
      lessonId: lesson.id,
      mediaId: lesson.mediaId,
      id: course.id,
    },
  });
  window.open(routeUrl.href, "_blank");
};
</script>

<style lang="less" scoped>
.course-tabs {
  padding: 0;
  margin: 0;
  display: flex;
  background: #f9f9fa;
  > li {
    padding: 12px 0;
    flex: 1;
    cursor: pointer;
    text-align: center;
    border-top: 2px solid transparent;
    font-weight: 600;
    &.active {
      background: #fff;
      border-color: @primary-color;
    }
  }
  // :deep(.ant-tabs-nav) {
  //   padding: 0 20px !important;
  // }
}

.tabs-content {
  padding: 14px 0;
  &-title {
    margin: 12px 18px;
    padding-left: 14px;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 3px;
      width: 3px;
      height: 18px;
      background: @primary-color;
    }
  }
}

.detail-header {
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  .header-container {
    display: flex;
    padding: 50px 0;
    > div {
      flex: 1;
    }
    .detail-header__left {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 500px;
      }
    }
    .detail-header__right {
      padding: 0 50px;
      h3 {
        font-size: 26px;
        color: #333;
        font-weight: 800;
        margin: 14px 0 20px 0;
      }
      p {
        color: #444;
        font-size: 16px;
      }
      .pay {
        padding-top: 20px;
        margin-top: 30px;
        border-top: 1px solid #ff4c4c;
        display: flex;
        align-items: flex-end;
        .left {
          flex: 1;
          .pay-history-price {
            span {
              margin-left: 5px;
              text-decoration: line-through;
            }
          }
          .pay-price {
            color: #ff4c4c;
            span {
              font-size: 32px;
              margin-left: 10px;
              font-weight: 500;
            }
          }
        }
        .pay-btn {
          margin-bottom: 30px;
        }
      }
    }
  }
}
.detail {
  display: flex;
  margin: 20px 0;
  .left {
    background: #fff;
    flex: 1;
    .info {
      img {
        width: 100%;
      }
    }
    .lesson {
      padding: 0 20px;
    }
  }
  .right {
    width: 300px;
    flex-shrink: 0;
    margin-left: 20px;
    .card {
      margin-bottom: 15px;
      :deep(.ant-card-body) {
        padding: 10px 24px;
      }
    }
    .title {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc;
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: 20px;
      }
      p {
        margin: 0;
      }
    }
    .content {
      margin-top: 10px;
      text-align: left;
    }
  }
}
:deep(.empty) {
  margin-top: 100px;
}
</style>
