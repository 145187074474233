<template>
  <div class="empty">
    <img
      class="empty-img"
      src="//static.yanzhishi.cn/images/wechat/my_course_empty.png"
      alt="课程为空"
    />
    <p>{{ state.text }}</p>
  </div>
</template>

<script setup>
import { defineProps, watch, reactive } from "vue";
const props = defineProps(["text"]);
const state = reactive({
  text: "刷完题，听听课效果更好喔~",
});
// 监听props改变
watch(
  props,
  (newProps) => {
    if (newProps.text) state.text = newProps.text;
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>

<style lang="less">
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  p {
    color: #999;
  }
}
.empty-img {
  width: 300px;
}
</style>
