<template>
  <div class="subject">
    <div class="common-body">
      <div class="common-container">
        <h1 class="subject__title">计算机/电气考研题库5万+道等你来刷</h1>
        <div class="code">
          <div class="code-item">
            <p>计算机刷题题库</p>
            <img
              class="subject-qrcode"
              src="//static.yanzhishi.cn/images/pc/2023/qrcode_new.png"
              alt="题库"
            />
            <div class="subject-btn">手机扫码刷题</div>
          </div>
          <div class="code-item">
            <p>电气刷题题库</p>
            <img
              class="subject-qrcode"
              src="//static.yanzhishi.cn/images/pc/2023/qrcode_dianqi.png"
              alt="题库"
            />
            <div class="subject-btn">手机扫码刷题</div>
          </div>
        </div>
        <img
          class="subject-note"
          src="//static.yanzhishi.cn/images/pc/2023/tip%402x.png"
          alt="题库"
        />
      </div>
    </div>
    <img
      class="subject-bg"
      src="//static.yanzhishi.cn/images/pc/2023/subject_bg.png"
      alt="题库"
    />
  </div>
</template>

<script>
export default {
  name: "Subject",
};
</script>

<style lang="less" scoped>
.subject {
  background: #f7f6f4;
  height: 100%;
  position: relative;
  text-align: left;
}
.common-body {
  height: 100%;
}
.subject__title {
  margin-top: 8%;
  text-align: left;
  color: #f1943e;
  font-size: 36px;
}
.subject-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 900px;
}
.subject-qrcode {
  display: block;
  width: 150px;
  margin: 0 0 0 8px;
}
.subject-btn {
  display: inline-block;
  background: #f1943e;
  color: #fff;
  font-size: 18px;
  padding: 5px 20px 4px 20px;
  border-radius: 20px;
  margin-top: 20px;
  letter-spacing: 3px;
  font-weight: 800;
}
.subject-note {
  margin-top: 80px;
}
.code {
  display: flex;
  &-item {
    margin: 60px 80px 0 0;
    p {
      color: #f1943e;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
    }
  }
}
</style>
