/**
 * 判断是否为空
 */
export function validatenull(val) {
  if (val instanceof Array) {
    if (val.length === 0) {
      return true;
    }
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === "{}") {
      return true;
    }
  } else {
    if (
      val === "null" ||
      val === null ||
      val === "undefined" ||
      val === undefined ||
      val === ""
    ) {
      return true;
    }
    return false;
  }
  return false;
}

/**
 * 存储sessionStorage
 */
export const setStore = (params) => {
  const { name, content, type } = params;
  const obj = {
    dataType: typeof content,
    content: content,
    type: type,
    datetime: new Date().getTime(),
  };
  // 直接放到sessionStorage
  window.sessionStorage.setItem(name, JSON.stringify(obj));
};

/**
 * 获取sessionStorage
 */
export const getStore = (params) => {
  const { name } = params;
  let obj = {};
  let content;
  obj = window.sessionStorage.getItem(name);
  if (validatenull(obj)) return;
  obj = JSON.parse(obj);
  if (obj.dataType === "string") {
    content = obj.content;
  } else if (obj.dataType === "number") {
    content = Number(obj.content);
  } else if (obj.dataType === "object") {
    content = obj.content;
  } else if (obj.dataType === "boolean") {
    content = obj.content;
  }
  return content;
};

export function setToken(token) {
  return setStore({ name: "access_token", content: token });
}

export function getToken() {
  return getStore({ name: "access_token" });
}

/**
 * 删除sessionStorage
 */
export const removeStore = (params) => {
  const { name } = params;
  window.sessionStorage.removeItem(name);
};

export function getRefreshToken() {
  return getStore({ name: "refresh_token" });
}

export function setRefreshToken(token) {
  return setStore({ name: "refresh_token", content: token });
}
