<template>
  <div v-loading="state.loading" style="height: 100vh">
    <div v-show="!state.loading" class="pay-mobile">
      <div class="order">
        <h3>订单详情</h3>
        <div class="order-detail">
          <img :src="course.courseCover" alt="课程封面" />
          <div class="right">
            <h3>{{ course.courseName }}</h3>
            <p v-if="course.enableBargain === 1 ">¥{{ state.orderDetail.amount }}</p>
            <p v-else>¥{{ course.commodityPrice }}</p>
          </div>
        </div>
      </div>
      <div class="way">
        <div class="way__item" v-for="item in payWayList" :key="item.value">
          <a-radio
            v-model:checked="item.checked"
            :value="item.value"
            @click.stop="(e) => emit('change', { e, item })"
          >
            <img :src="item.img" />
            <span class="way-label">{{ item.label }}</span>
          </a-radio>
        </div>
      </div>
      <div class="link">
        <p>*分期实付金额以第三方平台为准</p>
        <div v-if="!state.isCreated" class="capsule" @click="handleCreateOrder">
          立即下单
        </div>
        <div v-else class="capsule" @click="handlePay(null)">马上支付</div>
      </div>
      <OrderList
        :visible="state.visible"
        :course="course"
        :orderList="state.orderList"
        @close="state.visible = false"
        @pay="handlePay"
      ></OrderList>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, reactive, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  payOrderByWx,
  createOrderByMobile,
  createPrepay,
  queryUnpayList,
  createJsapiSignature,
} from "@/api/order";
import { getStore, setToken, setStore } from "@/utils/auth";
import { getUserInfo } from "@/api/login";
import { message } from "ant-design-vue";
import wx from "weixin-js-sdk";
import OrderList from "@/components/mobile/orderList";

const props = defineProps(["payWayList", "course"]);
const emit = defineEmits(["change", "submit"]);
const router = useRouter();
const route = useRoute();

const state = reactive({
  unionid: "",
  userInfo: {},
  isCreated: false,
  couseId: "",
  orderDetail: {
    amount: 0,
  }, // 下单详情
  loading: true,
  visible: false,
  orderList: [],
});

const handleCreateOrder = () => {
  // 未绑定手机号，跳转至登录页绑定手机号
  if (!state.userInfo.phone) {
    router.push({
      path: "/login",
      query: {
        id: state.courseId,
        code: route.query.code,
        unionid: state.unionid,
      },
    });
    return;
  }
  // 创建订单
  const target = props.payWayList.find((way) => way.checked);
  createOrderByMobile({
    id: route.query.id,
    joinBargain: false, // 是否参与砍价
    unionid: state.unionid,
    pageUrl: window.location.href,
    payType: target.value,
  }).then((res) => {
    console.log("createOrderByMobile", res);
    if (res.code === 200) {
      setStore({
        name: "order",
        content: res.data,
      });
      state.isCreated = true;
      message.success("下单成功，点击【马上支付】完成支付");
    } else {
      message.error(res.msg);
    }
  });
};

const handlePay = async (orderItem) => {
  if (!state.unionid) return;
  const orderDetail = orderItem ? orderItem : getStore({ name: "order" });
  const target = orderItem
    ? orderItem.payType
    : props.payWayList.find((way) => way.checked).value;
  console.log("orderDetailByPay", orderDetail);
  const { data: jsapiSignature } = await createJsapiSignature({
    pageUrl: window.location.href,
  });
  console.log("jsapiSignature", jsapiSignature);
  createPrepay({
    id: orderDetail.id,
    payType: target,
    unionid: state.unionid,
    pageUrl: window.location.href,
  }).then((response) => {
    console.log("createPrepay", response);
    if (target == 2) {
      const res = response.data.payInfo;
      wx.config({
        appId: jsapiSignature.appId, // 必填，公众号的唯一标识
        timestamp: jsapiSignature.timestamp.toString(), // 必填，生成签名的时间戳
        nonceStr: jsapiSignature.nonceStr, // 必填，生成签名的随机串
        signature: jsapiSignature.signature, // 必填，签名，见附录1
        jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表，这里只写支付的
      });
      wx.chooseWXPay({
        timestamp: res.timeStamp, // 支付签名时间戳
        nonceStr: res.nonceStr, // 支付签名随机串，不长于32 位
        package: res.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
        signType: res.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: res.paySign, // 支付签名
        success: function () {
          // 成功支付回调
          router.push({
            path: `/detail`,
            query: {
              id: state.courseId,
            },
            replace: true,
          });
        },
        cancel: function (res) {
          // 取消支付回调
          console.log(res);
        },
        fail: function (res) {
          console.log(res);
        },
      });
    } else {
      // 支付宝支付
      window.location.href = `https://openapi.alipay.com/gateway.do?${response.data}`;
    }
  });
};

onMounted(async () => {
  // 获取couseId
  state.courseId = route.query.id;
  // 已经获取过unionid
  if (route.query.unionid) {
    state.unionid = route.query.unionid;
    nextTick(() => {
      state.loading = false;
    });
    state.isCreated = true;
    // 获取订单信息
    state.orderDetail = getStore({ name: "order" });
    return;
  }
  // 没有code，重定向获取code
  const wxCode = route.query.code;
  if (!wxCode) {
    const url = encodeURIComponent(
      `${process.env.VUE_APP_BASE_URL}/pay?id=${route.query.id}`
    );
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2a138edc6d060be7&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
  } else {
    // 获取unionid
    const res = await payOrderByWx({ code: route.query.code });
    state.unionid = res.unionid;
    setToken(res.access_token);
    console.log("unionid", res);
    // 登录态获取用户信息
    const userResponse = await getUserInfo();
    state.userInfo = userResponse.data;
    console.log("userInfo", userResponse);
    state.loading = false;
    // 获取未支付订单
    const orderResponse = await queryUnpayList({ courseId: state.courseId });
    console.log("orderList", orderResponse);
    state.orderList = orderResponse.data;
    if (state.orderList.length > 0) state.visible = true;
  }
});
</script>

<style lang="less" scoped>
.pay-mobile {
  .order {
    padding: 30px 20px;
    border-bottom: 12px solid #f3f3f3;
    > h3 {
      font-size: 16px;
      font-weight: 800;
      color: #333;
    }
    .order-detail {
      display: flex;
      img {
        width: 100px;
      }
      .right {
        margin-left: 12px;
        flex: 1;
        h3 {
          font-size: 14px;
          margin-top: 6px;
        }
        p {
          color: #ff4c4c;
          font-size: 22px;
          font-weight: 800;
          margin: 0;
        }
      }
    }
  }
  .way {
    padding: 0 10px;
    .way__item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #f1f1f1;
      img {
        width: 40px;
      }
      .way-label {
        flex: 1;
        margin-left: 10px;
        font-size: 16px;
      }
    }
    :deep(.ant-radio-wrapper) {
      width: 100%;
      display: flex;
      flex-flow: row-reverse;
      span {
        &:nth-child(2) {
          flex: 1;
        }
      }
    }
  }
  .link {
    margin-top: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin-bottom: 6px;
      color: #666;
    }
    .capsule {
      background: #f4de66;
      width: 80%;
      line-height: 40px;
      border-radius: 20px;
      text-align: center;
    }
  }
}
</style>
