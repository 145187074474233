import { createVNode, nextTick, render } from "vue";
import Loading from "@/components/Loading";

const toggleLoading = (el, binding) => {
  if (binding.value) {
    el.style.position = "relative";
    el.style.minHeight = "500px";
    nextTick(() => {
      el.appendChild(el.$instance);
    });
  } else {
    el.$instance.parentNode?.removeChild(el.$instance);
  }
};

export default {
  // 指令的定义
  mounted: function (el, binding) {
    const app = createVNode(Loading);
    render(app, document.createElement("div"));
    el.$instance = app.el;
    binding.value && toggleLoading(el, binding);
  },
  updated: function (el, binding) {
    if (binding.oldValue !== binding.value) {
      toggleLoading(el, binding);
    }
  },
  unmounted: function (el) {
    el.instance && el.instance.$destroy();
  },
};
