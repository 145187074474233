<template>
  <div class="column-flex">
    <div class="bargain">
      <div class="bargain-course">
        <div>
          <img :src="state.course.courseCover" alt="课程封面" />
        </div>
        <div class="bargain-course__right">
          <h3>{{ state.course.courseName }}</h3>
          <p class="subtitle">
            已有{{ state.orderDetail.bargainUserNum }}人帮你砍价<span>{{
              state.orderDetail.bargainTotalAmount.toFixed(2)
            }}</span
            >元，最高可砍<span>{{ state.orderDetail.bargainMaxPrice }}</span
            >元
          </p>
          <p class="price">¥{{ state.orderDetail.amount }}</p>
        </div>
      </div>
      <div class="bargain-progress">
        <p>
          <span v-if="state.percent > 20"
            >原价{{ state.orderDetail.amount }}</span
          >
        </p>
        <a-progress
          :percent="state.percent"
          :showInfo="false"
          strokeColor="#ff4c4c"
        />
        <div class="bubble" :style="{ left: state.bubbleLeft }">
          现价{{ state.orderDetail.amount }}
        </div>
      </div>
      <div class="bargain-btn">
        <a-button class="share" @click="shareUser">邀请好友砍价</a-button>
        <a-button class="pay" @click="toPay">不砍了，买啦</a-button>
      </div>
    </div>
    <div class="rank">
      <div class="rank-title">—— 砍价榜 ——</div>
      <div class="list-title">
        <div>好友</div>
        <div>时间</div>
        <div>砍掉价格</div>
      </div>
      <ul
        v-if="state.orderDetail.bargainRecordList.length > 0"
        class="list-content"
      >
        <li
          class="list-content__item"
          v-for="item in state.orderDetail.bargainRecordList"
          :key="item.id"
        >
          <div class="avatar">
            <img
              :src="
                item.avatar ||
                '//static.yanzhishi.cn/images/wechat/entry/share_user.png'
              "
              alt=""
            />
            <span>{{ item.nickname || "-" }}</span>
          </div>
          <div class="time">{{ formatTime(item.createTime) }}</div>
          <div class="price">¥{{ item.amount }}</div>
        </li>
      </ul>
      <div v-else class="rank-empty">
        <img :src="emptyImg" />
        <p>快快邀请好友来砍价啦~~</p>
      </div>
    </div>
  </div>
  <ShareModal
    :visible="state.shareVisible"
    @close="state.shareVisible = false"
  ></ShareModal>
</template>

<script setup>
import ShareModal from "@/components/ShareModal.vue";
import { queryCourseById, jsSign } from "@/api/course";
import { onMounted, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import emptyImg from "@/assets/images/empty.jpg";
import { getStore, setToken, setStore } from "@/utils/auth";
import { getUserInfo } from "@/api/login";
import {
  payOrderByWx,
  createOrderByMobile,
  createPrepay,
  queryUnpayList,
  createJsapiSignature,
  queryOrderDetail,
} from "@/api/order";
import { message } from "ant-design-vue";
import wx from "weixin-js-sdk";
import dayjs from "dayjs";

const router = useRouter();
const route = useRoute();

const state = reactive({
  course: {},
  unionid: "",
  pageLoading: true,
  userInfo: {},
  orderDetail: {
    amount: 0,
    bargainTotalAmount: 0,
    bargainRecordList: [],
  },
  percent: 0,
  bubbleLeft: "-12px",
  shareVisible: false,
});

const formatTime = (time) => {
  return dayjs(time).format("YYYY-MM-DD HH:mm:ss");
};

const wxShare = () => {
  jsSign({
    pageUrl: window.location.href,
  }).then((res) => {
    const config = res.data;
    wx.config({
      // debug: true,
      appId: config.appId, // 必填，公众号的唯一标识
      timestamp: config.timestamp.toString(), // 必填，生成签名的时间戳
      nonceStr: config.nonceStr, // 必填，生成签名的随机串
      signature: config.signature, // 必填，签名，见附录1
      jsApiList: [
        "checkJsApi",
        "updateAppMessageShareData", //分享给好友
        "updateTimelineShareData", //分享到朋友圈
        "onMenuShareAppMessage",
        "onMenuShareTimeline",
      ], // 必填，需要使用的JS接口列表，这里只写支付的
    });
    wx.ready(function () {
      wx.updateAppMessageShareData({
        title: state.course.courseName, //标题
        desc: "快来快来~", //描述
        link: `${process.env.VUE_APP_BASE_URL}/detail?id=${route.query.id}&sn=${state.orderDetail.sn}`, //链接
        imgUrl: state.course.courseCover, //图片地址
        success: function () {
          //里面是分享成功后得回调
        },
      });
      wx.updateTimelineShareData({
        title: state.course.courseName, //标题
        desc: "快来快来~", //描述
        link: `${process.env.VUE_APP_BASE_URL}/detail?id=${route.query.id}&sn=${state.orderDetail.sn}`, //链接
        imgUrl: state.course.courseCover, //图片地址
        success: function () {
          //里面是分享成功后得回调
        },
      });
      wx.onMenuShareAppMessage({
        title: state.course.courseName, //标题
        desc: "快来快来~", //描述
        link: `${process.env.VUE_APP_BASE_URL}/detail?id=${route.query.id}&sn=${state.orderDetail.sn}`, //链接
        imgUrl: state.course.courseCover, //图片地址
        success: function () {
          //里面是分享成功后得回调
        },
      });
      wx.onMenuShareTimeline({
        title: state.course.courseName, //标题
        desc: "快来快来~", //描述
        link: `${process.env.VUE_APP_BASE_URL}/detail?id=${route.query.id}&sn=${state.orderDetail.sn}`, //链接
        imgUrl: state.course.courseCover, //图片地址
        success: function () {
          //里面是分享成功后得回调
        },
      });
    });
  });
};

const shareUser = () => {
  state.shareVisible = true;
};

const getCourseById = () => {
  // 获取课程
  queryCourseById({ id: route.query.id }).then((res) => {
    state.course = res;
    state.pageLoading = false;
  });
};

// 创建订单
const createOrder = async () => {
  const res = await createOrderByMobile({
    id: route.query.id,
    joinBargain: true, // 是否参与砍价
    unionid: state.unionid,
    pageUrl: window.location.href,
  });

  if (res.code === 200) {
    setStore({
      name: "order",
      content: res.data,
    });
    // 获取订单详情
    const orderRes = await queryOrderDetail({ sn: res.data.sn });
    state.orderDetail = orderRes.data;
    if (Object.keys(state.orderDetail).length !== 0) {
      state.percent = Math.round(
        state.orderDetail.bargainTotalAmount / state.orderDetail.bargainMaxPrice
      );
      state.bubbleLeft = `calc(${state.percent} - 12px)`;
    }
    wxShare();
    // console.log("orderDetail", orderDetail);
  } else {
    message.error(res.msg);
  }
};

const toPay = () => {
  router.push({
    path: "/pay",
    query: {
      id: route.query.id,
      sn: state.orderDetail.sn,
      unionid: state.unionid,
    },
  });
};

onMounted(async () => {
  getCourseById();
  // 没有code，重定向获取code'
  const wxCode = route.query.code;
  if (!wxCode) {
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2a138edc6d060be7&redirect_uri=${encodeURIComponent(
      `${process.env.VUE_APP_BASE_URL}/bargain?id=${route.query.id}`
    )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
  } else {
    // 获取unionid
    const res = await payOrderByWx({ code: route.query.code });
    state.unionid = res.unionid;
    setToken(res.access_token);
    console.log("unionid", res);
    // 登录态获取用户信息
    const userResponse = await getUserInfo();
    state.userInfo = userResponse.data;
    console.log("userInfo", userResponse);
    createOrder();
  }
});
</script>

<style lang="less" scoped>
.column-flex {
  background: #fff9ec;
  min-height: 100%;
}
.bargain {
  padding: 20px 20px 30px 20px;
  background: #fff;
}
.bargain-course {
  display: flex;
  img {
    width: 180px;
  }
  &__right {
    flex: 1;
    margin-left: 10px;
    overflow: hidden;
    h3 {
      font-size: 16px;
      margin: 6px 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    p {
      margin: 0;
    }
    .subtitle {
      color: #999;
      font-size: 14px;
      span {
        color: #ff4c4c;
      }
    }
    .price {
      color: #ff4c4c;
      font-size: 20px;
      font-weight: 600;
    }
  }
}
.bargain-progress {
  margin-top: 25px;
  position: relative;
  p {
    margin-bottom: -5px;
    height: 20px;
  }
  .bubble {
    position: absolute;
    top: -12px;
    left: calc(30% - 12px);
    border: 1px solid #ff4c4c;
    border-radius: 4px;
    padding: 2px 8px;
    font-size: 12px;
    color: #ff4c4c;
    line-height: 20px;
    background: #fff;
    &::before {
      content: "";
      position: absolute;
      left: 8px;
      bottom: -16px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent;
      border-top-color: #ff4c4c;
    }
    &::after {
      content: "";
      position: absolute;
      left: 16px;
      bottom: -15px;
      transform: translate(-50%, 0);
      border-width: 8px;
      border-style: solid;
      border-color: transparent;
      border-top-color: #fff;
    }
  }
}
.bargain-btn {
  margin-top: 10px;
  display: flex;
  .share {
    flex: 1;
    margin-right: 5px;
    color: #fff;
    background: #fec638;
    border-color: #fec638;
    border-radius: 4px;
    height: 36px;
  }
  .pay {
    flex: 1;
    margin-left: 5px;
    color: #fff;
    background: #ff4c4c;
    border-color: #ff4c4c;
    border-radius: 4px;
    height: 36px;
    &:active {
      border: none;
    }
  }
}
.rank {
  padding: 0 20px;
  .rank-title {
    font-size: 18px;
    text-align: center;
    margin: 20px 0;
  }
  .rank-empty {
    padding-top: 50px;
    text-align: center;
    img {
      width: 65%;
      margin-left: -10px;
    }
    p {
      color: #aaa;
      margin-top: 30px;
      font-size: 16px;
    }
  }
  .list-title {
    display: flex;
    margin-bottom: 14px;
    > div {
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }
  .list-content {
    margin: 0;
    padding: 0;
    &__item {
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      > div {
        flex: 1;
        display: flex;
        justify-content: center;
      }
      .avatar {
        display: flex;
        align-items: center;
        overflow: hidden;
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 12px;
        }
        span {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .price {
        color: #ff4c4c;
      }
      .time {
        white-space: nowrap;
      }
    }
  }
}
</style>
