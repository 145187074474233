<template>
  <div class="content">
    <div class="input-box">
      <div class="input-text">
        <a-input
          v-model:value="state.phone"
          type="text"
          placeholder="请输入手机号"
          placeholder-style="color:#575757;"
        />
      </div>
      <div class="input-text">
        <a-input
          v-model:value="state.verify"
          type="text"
          placeholder="请输入验证码"
          placeholder-style="color:#575757;"
        />
        <div v-if="state.showTime" class="time">{{ state.time }}S</div>
        <span v-else class="verify" @click="showVerity">获取验证码</span>
      </div>
      <div v-show="state.visible" id="sc" class="smart"></div>
    </div>
    <div
      :class="[
        'login-btn',
        state.verify.length > 0 && state.finishSend && 'active',
      ]"
      @click="loginUser"
    >
      确定
    </div>
  </div>
  <!-- <a-modal
    wrapClassName="smart-modal"
    v-model:visible="state.visible"
    title="智能验证"
    :footer="null"
    @ok="handleOk"
  >
    <div id="sc" class="smart"></div>
  </a-modal> -->
</template>

<script setup>
import { message } from "ant-design-vue";
import {
  onUnmounted,
  reactive,
  nextTick,
  defineEmits,
  defineProps,
  watch,
} from "vue";
import { bindUserPhoneByPc, getVerityCodeByPc } from "@/api/login";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const props = defineProps(["visible"]);
const emit = defineEmits(["success"]);

const state = reactive({
  phone: "",
  verify: "",
  showTime: false,
  time: 60,
  timer: null,
  finishSend: false,
  visible: false,
  showSmart: false,
});

const showVerity = () => {
  if (!/^[0-9]{11}$/.test(state.phone.trim())) {
    message.error("手机号格式错误");
    return;
  }
  state.visible = true;
  nextTick(() => {
    if (!window.ic) {
      createAWSC();
    } else {
      window.ic.reset();
    }
  });
};

const createAWSC = () => {
  const AWSC = window.AWSC;
  AWSC.use("ic", function (sta, module) {
    // 初始化
    window.ic = module.init({
      // 应用类型标识。它和使用场景标识（scene字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
      appkey: "FFFF0N0000000000B6C5",
      // 使用场景标识。它和应用类型标识（appkey字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的scene值，请务必正确填写。
      scene: "ic_login_h5",
      // 声明智能验证需要渲染的目标元素ID。
      renderTo: "sc",
      // 验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
      // default_txt: "获取验证码",
      success: function (data) {
        getVerityCodeByPc({
          sessionId: data.sessionId,
          sig: data.sig,
          token: data.token,
          mobile: state.phone,
        }).then((res) => {
          console.log("getSms", res);
          if (res.code === 200) {
            state.finishSend = true;
            // state.visible = false;
            state.showTime = true;
            state.time = 60;
            state.timer = setInterval(() => {
              if (state.time === 0) {
                state.showTime = false;
                clearInterval(state.timer);
                state.timer = null;
              } else {
                state.time--;
              }
            }, 1000);
          } else {
            message.error(res.msg);
            window.ic.reset();
          }
        });
      },
      // 验证失败时触发该回调参数
      fail: function (failCode) {
        console.log(failCode);
      },
      // 验证码加载异常时触发该回调参数
      error: function (errorCode) {
        console.log(errorCode);
      },
    });
  });
};

const loginUser = () => {
  bindUserPhoneByPc({
    mobile: state.phone,
    smsCode: state.verify,
  }).then(() => {
    emit("success", { phone: state.phone });
  });
};

watch(props, (newProps) => {
  // 重新初始化数据
  if (newProps.visible) {
    state.visible = false;
    state.phone = "";
    state.verify = "";
    if (state.timer) clearInterval(state.timer);
  }
});

onUnmounted(() => {
  if (state.timer) clearInterval(state.timer);
});
</script>

<style lang="less" scoped>
.input-text {
  display: flex;
  align-items: center;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding-left: 8px;
  height: 40px;
  margin-bottom: 20px;
  img {
    width: 15px;
    flex-shrink: 0;
    margin: 0 20px;
  }
  input {
    flex: 1;
    height: 40px;
    text-align: left;
    color: #575757;
    border: none;
    background-color: transparent;
    padding: 0;
    &:focus-visible,
    &:focus {
      outline-color: transparent;
      padding: 0;
      box-shadow: none;
    }
  }
  .verify {
    color: #575757;
    margin: 0 8px;
    padding-right: 10px;
    cursor: pointer;
  }
}

.smart {
  display: flex;
  justify-content: center;
  margin: 0 !important;
}

.login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #5b5b5b;
  background-color: #fafafa;
  height: 40px;
  border-radius: 20px;
  margin-top: 24px;
  &.active {
    background-color: #fddb00;
    color: #555;
  }
}

.time {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 40px;
  color: #555;
}
</style>

<style lang="less">
.smart-modal {
  .smart {
    display: flex;
    justify-content: center;
    margin: 0 !important;
  }
  .ant-modal {
    top: 50%;
    margin-top: -100px;
    max-width: calc(100vw - 46px);
  }
}
</style>
