<template>
  <component
    :is="state.currentComponent"
    :payWayList="state.payWayList"
    :course="state.course"
    :loading="loading"
    @change="changePayWay"
  ></component>
</template>

<script setup>
import Pc from "./PC";
import Mobile from "./Mobile";
import { onMounted, reactive, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { isMobile } from "@/utils/tool";
import { PAY_WAY } from "@/utils/const";
import { queryCourseById } from "@/api/course";

const router = useRouter();
const route = useRoute();

const state = reactive({
  currentComponent: Pc,
  payWayList: PAY_WAY,
  course: {},
  pageLoading: true,
});

let loading = computed(() => {
  let show = true;
  if (state.pageLoading) {
    show = true;
  } else if (JSON.stringify(state.course) !== "{}") {
    show = false;
  }
  return show;
});

const changePayWay = ({ e, item }) => {
  const target = state.payWayList.find((way) => way.value === item.value);
  if (target.checked !== e.target.checked) {
    state.payWayList = state.payWayList.map((way) => {
      way.checked = way.value === item.value;
      return { ...way };
    });
  }
};

onMounted(() => {
  if (isMobile()) {
    state.currentComponent = Mobile;
    state.payWayList = PAY_WAY.map((item) => {
      if (item.value === 2) {
        return { ...item, checked: true };
      } else {
        return { ...item, checked: false };
      }
    }).reverse();
  }
  queryCourseById({ id: route.query.id }).then((res) => {
    state.course = res;
    if (state.course.own) {
      router.push({
        path: "/detail",
        query: {
          id: route.query.id,
        },
      });
    }
    state.pageLoading = false;
  });
});
</script>
