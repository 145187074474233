<template>
  <div class="video">
    <div class="left">
      <!-- <video id="myVideo" controls autoplay></video> -->
      <VideoPlayer ref="refVideo" v-bind="options"></VideoPlayer>
      <img
        class="close-img"
        :src="state.videoBase64"
        alt="折叠"
        @click="switchShow"
      />
    </div>
    <div v-show="state.showDirectory" class="right">
      <Directory
        :active="state.activeKey"
        @view="clickLesson"
        @backQuery="initVideo"
      ></Directory>
    </div>
  </div>
</template>

<script setup>
import { getStore, setStore } from "@/utils/auth";
import { onMounted, reactive, ref } from "vue";
import { getPlayInfo } from "@/api/course";
import CryptoJS from "crypto-js";
import { message } from "ant-design-vue";
import Directory from "@/components/Directory.vue";
import { VIDEO_IMG_OPEN, VIDEO_IMG_CLOSE } from "@/assets/base64";
import { useRouter, useRoute } from "vue-router";
import VideoPlayer from "@/components/videoPlayer";

const route = useRoute();
const refVideo = ref(); // 视频播放器

const options = reactive({
  width: "100%", //播放器高度
  height: "100%", //播放器高度
  color: "#ffc300", //主题色
  muted: false, //静音
  webFullScreen: false,
  speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
  autoPlay: true, //自动播放
  loop: false, //循环播放
  mirror: false, //镜像画面
  ligthOff: false, //关灯模式
  volume: 0.3, //默认音量大小
  control: true, //是否显示控制器
  title: "", //视频名称
  src: "", //视频源
  poster: "", //封面
  type: "m3u8",
});

const state = reactive({
  lessonList: {},
  detailCategory: [
    {
      id: 1,
      name: "课程介绍",
    },
    {
      id: 2,
      name: "课表目录",
    },
  ],
  playUrl: "",
  course: {},
  videoBase64: VIDEO_IMG_CLOSE,
  showDirectory: true,
  activeKey: null,
  refVideoPlayer: refVideo
});

const switchShow = () => {
  state.showDirectory = !state.showDirectory;
  state.videoBase64 = state.showDirectory ? VIDEO_IMG_CLOSE : VIDEO_IMG_OPEN;
  // 播放器自动获取焦点，方便键盘控制
  state.refVideoPlayer.inputFocusHandle()
};

const clickLesson = ({ lesson }) => {
  const { mediaId } = lesson;
  if (mediaId) {
    initXunKe(mediaId, lesson.id);
  } else {
    message.error("当前课时未上传视频，请反馈给助教");
  }
};

const aesKey = "L8wjy8NDGvxrfN3v";
const aesIv = "43tGKCEAqe7TFcwK";

const Decrypt = (word) => {
  const key = CryptoJS.enc.Utf8.parse(aesKey);
  const iv = CryptoJS.enc.Utf8.parse(aesIv);
  const encrypted = CryptoJS.AES.decrypt(word, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString(CryptoJS.enc.Utf8);
};

// const Encrypt = (word) => {
//   const key = CryptoJS.enc.Utf8.parse(aesKey);
//   const iv = CryptoJS.enc.Utf8.parse(aesIv);
//   const encrypted = CryptoJS.AES.encrypt(word, key, {
//     iv,
//     mode: CryptoJS.mode.CBC,
//     padding: CryptoJS.pad.Pkcs7,
//   });
//   return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
// };

const initXunKe = (mediaId, lessonId) => {
  // 播放器自动获取焦点，方便键盘控制
  state.refVideoPlayer.inputFocusHandle()
  
  var appId = "a9b71345-e115-45d7-8b1d-cde5ef1affe4"; //替换为您的appId
  var secretKey = "8c565862-4835-4fd5-b567-aad1a1f51aab"; //替换为您的secretKey
  var apiVersion = "ixk-v1"; //签名版本
  var timestamp = new Date().getTime(); //时间戳
  var expires = 300 * 1000; //设置签名有效期（单位毫秒），过期后需重新调用接口获取新的签名
  function xunkeCloudAuth(appId, secretKey, apiVersion, timestamp, expires) {
    //加密函数，服务端接口可按照此方法进行加密
    timestamp = timestamp + "";
    expires = expires + "";
    var kDate = CryptoJS.HmacSHA256(timestamp, secretKey);
    var Signature = CryptoJS.HmacSHA256(expires, kDate).toString();
    var Authorization = `${apiVersion} Credential=${appId},Signature=${Signature}`;
    return Authorization;
  }
  var authorData = {
    ixkTimeStamp: timestamp,
    ixkExpires: expires,
    ixkAuthorization: xunkeCloudAuth(
      appId,
      secretKey,
      apiVersion,
      timestamp,
      expires
    ),
  };
  getPlayInfo({
    ...authorData,
    mediaId: mediaId.trim(), //视频id，通过上传视频的回调获取，上传视频后请务必保存
    format: "m3u8",
  }).then((res) => {
    if (res.data.mediaList && res.data.mediaList.length) {
      const playUrl = Decrypt(res.data?.mediaList[0].playURL);
      options.src = playUrl;
      state.activeKey = ref(lessonId);
    } else {
      message.error("视频播放失败，请反馈给助教");
    }
  });
};

const initVideo = (lessonList) => {
  let firstLesson = lessonList[Object.keys(lessonList)[0]];
  const mediaId = route.query.mediaId || firstLesson[0].mediaId;
  const lessonId = route.query.lessonId || firstLesson[0].id;
  initXunKe(mediaId, lessonId);
};

onMounted(() => {
  state.course = getStore({ name: "course" });
});
</script>

<style lang="less" scoped>
.video {
  display: flex;
  height: 100%;
  overflow: hidden;
  .right {
    width: 350px;
    overflow-y: auto;
    background-color: #f5f5f5;
  }
  .left {
    flex: 1;
    position: relative;
    video {
      width: 100%;
      height: 100%;
      background: #000;
    }
    .close-img {
      position: absolute;
      right: -1px;
      top: 100px;
      opacity: 0.8;
      width: 16px;
      z-index: 100;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
