import wechatImg from "@/assets/images/wechat.png";
import alipayImg from "@/assets/images/alipay.png";

export const TOP_MENU = [
  {
    link: "$",
    name: "首页",
  },
  {
    link: "$",
    name: "题库",
  },
  {
    link: "$",
    name: "关于我们",
  },
  {
    link: "$",
    name: "登录/注册",
  },
];

export const PAY_WAY = [
  {
    img: alipayImg,
    label: "支付宝",
    value: 1,
    checked: true,
  },
  {
    img: wechatImg,
    label: "微信",
    value: 2,
    checked: false,
  },
];

export const PAY_PARAMS = {
  appId: "wx2a138edc6d060be7",
};


export const COURSE_TYPE = [
  { id: 4, name: "408统考", tenantCode: 1 },
  { id: 5, name: "自命题", tenantCode: 1 },
  { id: 1, name: "期末考", tenantCode: 1 },
  { id: 1, name: "电路", tenantCode: 2 },
  { id: 2, name: "电力系统分析", tenantCode: 2 },
  { id: 3, name: "数字电子技术", tenantCode: 2 },
  { id: 4, name: "模拟电子技术", tenantCode: 2 },
]