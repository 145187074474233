import request from "@/utils/axios";

export function payOrder(params) {
  return request({
    url: "/api/msc/pc/order/prepay",
    method: "post",
    data: params,
  });
}

export function payOrderByWx(params) {
  return request({
    url: "/api/auth/wx/token?grant_type=mp&code=" + params.code,
    method: "post",
    headers: {
      noAuth: true,
    },
  });
}

export function createPrepay(params) {
  return request({
    url: "/api/msc/wx/order/h5/prepay",
    method: "post",
    data: params,
  });
}

export function createOrder(params) {
  return request({
    url: '/api/msc/pc/order/create',
    method: "post",
    data: params
  })
}

export function createOrderByMobile(params) {
  return request({
    url: '/api/msc/wx/order/h5/create',
    method: "post",
    data: params
  })
}


export function queryUnpayList(params) {
  return request({
    url: '/api/msc/wx/order/unpay/order/list',
    method: 'get',
    params // courseId
  })
}

export function createJsapiSignature(params) {
  return request({
    url: '/api/msc/open/mp/js/sign',
    method: 'post',
    params
  })
}

export function queryOrderBySn(params) {
  return request({
    url: '/api/msc/pc/order/detail',
    method: 'get',
    params
  })
}

export function queryOrderList(params) {
  return request({
    url: '/api/msc/pc/order/list',
    method: 'get',
    params
  })
}

// 获取订单详情
export function queryOrderDetail({ sn }) {
  return request({
    url: `/api/msc/wx/order/h5/detail/${sn}`,
    method: 'get'
  })
}

// 砍价
export function bargainOrder({ sn }) {
  return request({
    url: `/api/msc/wx/order/h5/bargain/${sn}`,
    method: 'post'
  })
}