<template>
  <div v-loading="loading" style="height: 100vh">
    <div v-show="!loading" class="detail-mobile">
      <div class="detail-mobile__body">
        <img class="cover" :src="course.courseCover" alt="课程封面" />
        <div class="info">
          <h3>{{ course.courseName }}</h3>
          <p>
            有效期至：{{
              $filters.fmtDate(course.endTime, "yyyy-MM-dd") || "暂无"
            }}
          </p>
          <p>课时总数：{{ course.totalLesson || 0 }}</p>
          <div class="pay">
            <div class="left">
              <p class="pay-history-price">
                原价：¥<span>{{ course.underlinedPrice }}</span>
              </p>
              <p class="pay-price">
                ¥<span>{{ course.commodityPrice }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="introduce">
          <div class="title">课程介绍</div>
          <img :src="course.courseDetail" alt="课程详情" />
        </div>
      </div>
      <div class="detail-mobile__footer">
        <div v-if="state.shareBooster" class="jump">
          <div @click="beginBooster">帮我砍一刀</div>
        </div>
        <div
          v-else-if="course.enableBargain === 1 && !course.own"
          class="bargain"
        >
          <div @click="emit('toPay')">单独购买</div>
          <div @click="beginBargain">发起砍价</div>
        </div>
        <div
          v-else
          :class="['jump', course.own && 'disabled']"
          @click="emit('toPay')"
        >
          {{ course.own ? "已报名" : "立即报名" }}
        </div>
      </div>
    </div>
  </div>
  <BargainModal
    :visible="state.bargainVisible"
    :price="state.price"
    @close="state.bargainVisible = false"
  ></BargainModal>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { bargainOrder, payOrderByWx, queryOrderDetail } from "@/api/order";
import BargainModal from "@/components/BargainModal.vue";
import { getStore, setToken, setStore } from "@/utils/auth";
import { message } from "ant-design-vue";

const router = useRouter();
const route = useRoute();
defineProps(["course", "loading"]);
const emit = defineEmits(["toPay"]);

const state = reactive({
  bargainVisible: false,
  shareBooster: false,
});

const beginBargain = () => {
  router.push({
    path: "/bargain",
    query: {
      id: route.query.id,
    },
  });
};

const beginBooster = () => {
  bargainOrder({ sn: route.query.sn }).then((res) => {
    if (res.code === 200) {
      state.bargainVisible = true;
      state.shareBooster = false;
      state.price = res.data.toFixed(2);
    } else {
      state.bargainVisible = false;
      message.error(res.msg);
    }
  });
};

onMounted(async () => {
  // 如果是砍价分享且已登录
  if (route.query.sn && route.query.code) {
    state.shareBooster = true;
    const res = await payOrderByWx({ code: route.query.code });
    setToken(res.access_token);
    queryOrderDetail({ sn: route.query.sn });
  } else {
    state.shareBooster = false;
  }
});
</script>

<style lang="less" scoped>
.detail-mobile {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__body {
    flex: 1;
    overflow-y: scroll;
    .cover {
      width: 100%;
    }
    .info {
      padding: 24px 10px 10px 10px;
      h3 {
        font-size: 16px;
        color: #333;
        font-weight: 800;
        margin-bottom: 10px;
      }
      p {
        color: #444;
        font-size: 14px;
        margin: 0;
        margin-bottom: 6px;
      }
      .pay {
        display: flex;
        align-items: flex-end;
        .left {
          flex: 1;
          .pay-history-price {
            span {
              margin-left: 4px;
              text-decoration: line-through;
            }
          }
          .pay-price {
            color: #ff4c4c;
            font-size: 18px;
            span {
              font-size: 22px;
              margin-left: 4px;
              font-weight: 500;
            }
          }
        }
      }
    }
    .introduce {
      .title {
        margin: 0 10px;
        position: relative;
        padding-left: 10px;
        font-weight: 800;
        font-size: 16px;
        color: #333;
        margin-bottom: 10px;
        &::before {
          content: "";
          width: 2px;
          height: 16px;
          background: #ffc525;
          position: absolute;
          left: 0;
          top: 4px;
        }
      }
      img {
        width: 100%;
      }
    }
  }
  &__footer {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    .jump {
      background: #ff4c4c;
      color: #fff;
      font-weight: 800;
      letter-spacing: 2px;
      width: 80%;
      line-height: 40px;
      border-radius: 20px;
      text-align: center;
      font-size: 16px;
      &.disabled {
        background: #e1e1e1;
        color: #333;
      }
    }
    .bargain {
      display: flex;
      border-radius: 20px;
      overflow: hidden;
      div {
        line-height: 40px;
        padding: 0 40px;
        background: #ff4c4c;
        color: #fff;
        font-size: 16px;
        &:first-child {
          background: rgba(#ff4c4c, 0.6);
        }
      }
    }
  }
}
</style>
