<template>
  <component
    :is="state.currentComponent"
    :course="state.course"
    :loading="loading"
    :comments="state.comments"
    @toPay="toPay"
  ></component>
</template>

<script setup>
import { inject, computed } from "vue";
import Pc from "./PC";
import Mobile from "./Mobile";
import { onMounted, reactive } from "vue";
import { isMobile } from "@/utils/tool";
import { useRouter, useRoute } from "vue-router";
import { queryCourseById, jsSign, getCourseComment } from "@/api/course";
import wx from "weixin-js-sdk";
// import { createOrderByMobile, payOrderByWx } from "@/api/order";
// import { setToken, setStore } from "@/utils/auth";
// import { message } from "ant-design-vue";
// import { getUserInfo } from "@/api/login";

const router = useRouter();
const route = useRoute();
const $bus = inject("$bus");

const state = reactive({
  currentComponent: Pc,
  course: {},
  unionid: "",
  pageLoading: true,
  userInfo: {},
});

let loading = computed(() => {
  let show = true;
  if (state.pageLoading) {
    show = true;
  } else if (JSON.stringify(state.course) !== "{}") {
    show = false;
  }
  return show;
});

const toPay = async () => {
  if (state.course.own) return;
  // if (isMobile()) {
  //   const wxCode = route.query.code;
  //   if (!wxCode) {
  //     const url = encodeURIComponent(
  //       `${process.env.VUE_APP_BASE_URL}/detail?id=${route.query.id}`
  //     );
  //     window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2a138edc6d060be7&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
  //     return;
  //   }
  //   const orderDetail = await createOrderByMobile({
  //     id: route.query.id,
  //     unionid: state.unionid,
  //   });
  //   setStore({
  //     name: "order",
  //     content: orderDetail,
  //   });
  //   if (!state.userInfo.phone) {
  //     route.push({
  //       path: "/login",
  //     });
  //   }
  // }
  router.push({
    path: "/pay",
    query: {
      id: route.query.id,
    },
  });
};

const wxShare = () => {
  jsSign({
    pageUrl: process.env.VUE_APP_URL_ORIGIN,
  }).then((res) => {
    // alert(JSON.stringify(res) + 111);
    const config = res.data;
    wx.config({
      appId: config.appId, // 必填，公众号的唯一标识
      timestamp: config.timestamp.toString(), // 必填，生成签名的时间戳
      nonceStr: config.nonceStr, // 必填，生成签名的随机串
      signature: config.signature, // 必填，签名，见附录1
      jsApiList: [
        "checkJsApi",
        "updateAppMessageShareData", //分享给好友
        "updateTimelineShareData", //分享到朋友圈
        "onMenuShareQZone", //分享到qq空间
      ], // 必填，需要使用的JS接口列表，这里只写支付的
    });
    wx.updateAppMessageShareData({
      title: state.course.courseName, //标题
      desc: "快来快来~", //描述
      link: `${process.env.VUE_APP_BASE_URL}/detail?id=${route.query.id}`, //链接
      imgUrl: state.course.courseCover, //图片地址
      success: function () {
        //里面是分享成功后得回调
      },
    });
  });
};

const formatName = (name) => {
  if (name.length < 2) {
    return name
  } else {
    return name.substr(0, 1) + '***' + name.substr(-1)
  }
}

const getCourseById = () => {
  // 获取课程
  queryCourseById({ id: route.query.id }).then((res) => {
    state.course = res;
    state.pageLoading = false;
    if (isMobile()) {
      wxShare();
    }
  });
  getCourseComment({ courseId: route.query.id, pageNum: 1, pageSize: 100 }).then((res) => {
    state.comments = res.data.list.map(item => ({
      ...item,
      images: item.imgList.split(','),
      formatName: formatName(item.name)
    }))
  })
};

onMounted(async () => {
  $bus.on("updateCourse", () => {
    getCourseById();
  });
  if (isMobile()) {
    state.currentComponent = Mobile;
    const orderSn = route.query.sn;
    const wxCode = route.query.code;
    if (orderSn && !wxCode) {
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2a138edc6d060be7&redirect_uri=${encodeURIComponent(
        `${process.env.VUE_APP_BASE_URL}/detail?id=${route.query.id}&sn=${orderSn}`
      )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
    }
  }
  getCourseById();
});
</script>
