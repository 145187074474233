import request from "@/utils/axios";
import { setStore } from "@/utils/auth";

export function getHomeCourseList(params) {
  return request({
    url: "/api/exam/pc/course/platform/list",
    method: "get",
    params,
    headers: {
      'Tenant-Code': params.tenantCode
    }
  });
}

export function getCourseList(params) {
  return request({
    url: "/api/exam/pc/course/courseList",
    method: "get",
    params,
  });
}

export function getLessonList(params) {
  const { courseId } = params;
  let url = "/api/exam/pc/course/lessonList";
  if (typeof courseId !== "string") {
    delete params.courseId;
    url =
      "/api/exam/pc/course/lessonList?" +
      courseId.map((item) => `courseId=${item}`).join("&");
  }
  return request({
    url,
    method: "get",
    params,
    headers: {
      'Tenant-Code': params.tenantCode || 1
    }
  });
}

export function getPlayInfo(params) {
  return request({
    url: "/api/exam/wx/course/get_play_info",
    method: "get",
    params,
  });
}

export function checkCourseAuth(params) {
  return request({
    url: "/api/exam/wx/course/isOwnCourse/",
    method: "get",
    params,
  });
}

export const queryCourseById = async (params) => {
  const res = await request({
    url: "/api/exam/pc/course/detail",
    method: "get",
    params,
  });
  setStore({ name: "course", content: res.data });
  return {
    ...res.data,
    userVos: res.data?.userVos.filter((item) => item !== null),
  };
};

export function isOwnCourse(params) {
  return request({
    url: "/api/exam/pc/course/isOwnCourse",
    method: "get",
    params,
  });
}

export function jsSign(params) {
  return request({
    url: "/api/msc/open/mp/js/sign",
    method: "get",
    params,
  });
}

export function getBannerList() {
  return request({
    url: "/api/exam/pc/banner/platform/list",
    method: "get",
  })
}

export function getCourseComment(params) {
  return request({
    url: "/api/exam/pc/course/comment/list",
    method: "get",
    params
  })
}
