<template>
  <swiper
      :slidesPerView="1"
      :spaceBetween="30"
      :loop="true"
      :centeredSlides="true"
      :autoplay="{ delay: 2000, disableOnInteraction: false}"
      :pagination="{ clickable: true }"
    >
      <swiper-slide v-for="item in props.dataList" :key="item.id">
        <a v-if="item.detailUrl" :href="item.detailUrl" target="_blank">
          <img class="swiper-img" :src="item.bannerUrl" alt="">
        </a>
        <img v-else class="swiper-img" :src="item.bannerUrl" alt="">
      </swiper-slide>
    </swiper>
</template>

<script setup>
import { defineProps } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
SwiperCore.use([Autoplay, Pagination]);
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';

const props = defineProps(["dataList"]);
</script>

<style lang="less">
.swiper-img {
  width: 100%;
}
.swiper-pagination-bullet {
  width: 50px !important;
  height: 3px !important;
  border-radius: 2px !important;
  &.swiper-pagination-bullet-active {
    background: #fff !important;
  }
}
</style>