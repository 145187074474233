<template>
  <div class="wechat">
    <div class="wechat-container">
      <div class="wechat-price">
        <div>
          ¥<span>{{ state.orderDetail.amount }}</span>
        </div>
        <div>订单编号：{{ state.orderDetail.sn }}</div>
      </div>
      <div :class="['wechat-qrcode', state.payType == 1 && 'alipay']">
        <div class="wechat-qrcode__left">
          <div ref="qrcodeRef" id="qrcode"></div>
          <div class="img-tips">
            <svg
              t="1685019840232"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="2802"
              width="32"
              height="32"
            >
              <path
                d="M960 533.333333 64 533.333333c-12.8 0-21.333333-8.533333-21.333333-21.333333s8.533333-21.333333 21.333333-21.333333l896 0c12.8 0 21.333333 8.533333 21.333333 21.333333S972.8 533.333333 960 533.333333zM896 384c-12.8 0-21.333333-8.533333-21.333333-21.333333L874.666667 128 618.666667 128c-12.8 0-21.333333-8.533333-21.333333-21.333333s8.533333-21.333333 21.333333-21.333333l277.333333 0c12.8 0 21.333333 8.533333 21.333333 21.333333l0 256C917.333333 375.466667 908.8 384 896 384zM405.333333 128 149.333333 128l0 234.666667c0 12.8-8.533333 21.333333-21.333333 21.333333s-21.333333-8.533333-21.333333-21.333333L106.666667 106.666667c0-12.8 8.533333-21.333333 21.333333-21.333333l277.333333 0c12.8 0 21.333333 8.533333 21.333333 21.333333S418.133333 128 405.333333 128zM128 640c12.8 0 21.333333 8.533333 21.333333 21.333333l0 234.666667 256 0c12.8 0 21.333333 8.533333 21.333333 21.333333s-8.533333 21.333333-21.333333 21.333333L128 938.666667c-12.8 0-21.333333-8.533333-21.333333-21.333333L106.666667 661.333333C106.666667 648.533333 115.2 640 128 640zM618.666667 896l256 0L874.666667 661.333333c0-12.8 8.533333-21.333333 21.333333-21.333333s21.333333 8.533333 21.333333 21.333333l0 256c0 12.8-8.533333 21.333333-21.333333 21.333333L618.666667 938.666667c-12.8 0-21.333333-8.533333-21.333333-21.333333S605.866667 896 618.666667 896z"
                p-id="2803"
                fill="#ffffff"
              ></path>
            </svg>
            <span
              >请使用{{
                state.payType == 1 ? "支付宝" : "微信"
              }}扫一扫<br />扫描二维码支付</span
            >
          </div>
        </div>
        <div class="wechat-qrcode__right">
          <img :src="state.phoneImg" alt="phone" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import wx from "@/assets/images/phone_wx.png";
import alipay from "@/assets/images/phone_ali.png";
import { onMounted, reactive, ref, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { payOrder, queryOrderBySn } from "@/api/order";
import { queryCourseById, isOwnCourse } from "@/api/course";
import QRCode from "qrcodejs2";
import { message } from "ant-design-vue";

const route = useRoute();
const router = useRouter();
const qrcodeRef = ref();

const state = reactive({
  qrcode: "",
  phoneImg: wx,
  payType: 1,
  course: {},
  orderDetail: {},
  isOpen: false,
  isPriceChange: false,
  timer: null,
  timerOrder: null,
});

// 检测课程开通状态
const checkIsOwnCourse = () => {
  state.timer = setInterval(() => {
    if (state.isOpen) {
      clearInterval(state.timer);
      state.timer = null;
      message.success("支付成功，正在跳转到课程详情页");
      setTimeout(() => {
        router.push({
          path: `/detail`,
          query: {
            id: state.course.id,
          },
          replace: true,
        });
      }, 2000);
    }
    isOwnCourse({ courseCode: state.course.courseCode }).then((res) => {
      state.isOpen = res.data;
    });
  }, 2000);
};

// 查询订单价格修改
const checkOrderPriceChange = () => {
  state.timerOrder = setInterval(() => {
    if (state.isPriceChange) {
      clearInterval(state.timerOrder);
      state.timerOrder = null;
    }
    queryOrderBySn({
      sn: route.query.sn,
    }).then((res) => {
      state.orderDetail = res.data;
      if (res.data.amount !== state.course.commodityPrice) {
        createQrcode();
        state.isPriceChange = true;
      }
    });
  }, 8000);
};
// 生成二维码
const createQrcode = () => {
  payOrder({
    id: route.query.orderId,
    payType: parseInt(route.query.payType),
  }).then((res) => {
    qrcodeRef.value.innerHTML = "";
    state.qrcode = new QRCode("qrcode", {
      width: 188,
      height: 188,
      text: state.payType == 1 ? res.data.qrCode : res.data.codeUrl, // 二维码地址
    });
  });
};

onMounted(() => {
  state.payType = parseInt(route.query.payType);
  state.phoneImg = state.payType == 1 ? alipay : wx;
  state.courseId = route.query.id;
  queryCourseById({ id: state.courseId }).then((res) => {
    state.course = res;
  });
  checkIsOwnCourse();
  checkOrderPriceChange();
  createQrcode();
  queryOrderBySn({
    sn: route.query.sn,
  }).then((res) => {
    state.orderDetail = res.data;
  });
});

onUnmounted(() => {
  state.timer && clearInterval(state.timer);
});
</script>

<style lang="less" scoped>
.wechat {
  flex: 1;
  display: flex;
  justify-content: center;
  .wechat-container {
    width: 900px;
  }
  .wechat-price {
    margin-top: 60px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 24px;
      margin-left: 5px;
    }
  }
  .wechat-qrcode {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.alipay {
      .img-tips {
        background-color: #108ee9;
      }
      .wechat-qrcode__right {
        img {
          width: 280px;
        }
      }
    }
    &__left {
      margin-right: 200px;
      img {
        width: 200px;
      }
      .img-tips {
        margin-top: 10px;
        padding: 5px 0;
        width: 188px;
        background-color: #07c160;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: #fff;
          font-size: 12px;
          margin-left: 20px;
        }
      }
    }
    &__right {
      img {
        width: 200px;
      }
    }
  }
}
</style>
