<template>
  <div class="directory" v-loading="state.pageLoading">
    <div v-show="!state.pageLoading">
      <a-list
        v-if="(state.course.courses || []).length === 0"
        class="lesson"
        :locale="locale"
      >
        <a-list-item
          v-for="lesson in state.lessonList[state.course.id]"
          :key="lesson.id"
          :class="['lesson-item', state.activeKey === lesson.id && 'active']"
          @click="viewVideo(lesson, state.course)"
        >
          <div class="lesson-item__left">
            <img
              class="dot"
              src="//static.yanzhishi.cn/images/wechat/cheese2.png"
              alt="cheese"
            />
            <span class="course-name">{{ lesson.lessonName }}</span>
          </div>
          <template v-if="!state.course.own">
            <span v-if="lesson.isFree == 2" class="free">试学</span>
            <span v-else class="lock">
              <lock-outlined :style="{ color: '#80848f', fontSize: '18px' }" />
            </span>
          </template>
        </a-list-item>
      </a-list>
      <a-collapse v-else ghost expandIconPosition="right">
        <a-collapse-panel
          v-for="course in state.course.courses"
          :key="course.id"
          :header="course.courseName"
        >
          <Empty
            v-if="state.lessonList[course.id] === undefined"
            text="课程正在加急制作中..."
          ></Empty>
          <a-list v-else class="lesson" :locale="locale">
            <a-list-item
              v-for="lesson in state.lessonList[course.id]"
              :key="lesson.id"
              :class="[
                'lesson-item',
                state.activeKey === lesson.id && 'active',
              ]"
              @click="viewVideo(lesson, state.course)"
            >
              <div class="lesson-item__left">
                <img
                  class="dot"
                  src="//static.yanzhishi.cn/images/wechat/cheese2.png"
                  alt="cheese"
                />
                <span class="course-name">{{ lesson.lessonName }}</span>
              </div>
              <template v-if="!state.course.own">
                <span v-if="lesson.isFree == 2" class="free">试学</span>
                <span v-else class="lock">
                  <lock-outlined
                    :style="{ color: '#80848f', fontSize: '18px' }"
                  />
                </span>
              </template>
            </a-list-item>
          </a-list>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script setup>
import {
  onMounted,
  reactive,
  defineEmits,
  defineProps,
  watch,
  ref,
  inject,
  defineExpose,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { getLessonList, queryCourseById } from "@/api/course";
import { getStore, setStore } from "@/utils/auth";
import { LockOutlined } from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import Empty from "./Empty.vue";
const $bus = inject("$bus");
const route = useRoute();

const locale = ref({
  emptyText: <Empty text="课程正在加急制作中..." />,
});

const props = defineProps(["active"]);

watch(props, (newProps) => {
  state.activeKey = newProps.active;
});

const state = reactive({
  course: {},
  lessonList: [],
  activeKey: null,
  pageLoading: true,
});

const emit = defineEmits(["view", "backQuery"]);

const viewVideo = (lesson, course) => {
  if (lesson.isFree == 2 || course.own) {
    emit("view", { lesson, course });
  } else {
    notification.warning({
      message: "小主",
      description: "还没有开通课程哦~",
    });
  }
};

const queryLessonList = async () => {
  state.pageLoading = true;
  const course = await queryCourseById({ id: route.query.id });
  state.course = {
    ...course,
    courses: course.courseRelated.split(",").map((item) => {
      return course.courses.find((courseItem) => courseItem.id === item);
    }),
  };
  let courseId = course.id;
  if ((course.courses || []).length > 0) {
    let courseIds = course.courses.map((item) => item.id);
    const res = await getLessonList({
      courseId: courseIds,
      order: "desc",
      pageNum: 1,
      pageSize: 1000,
      sort: "sort",
      tenantCode: course.tenantCode
    });
    const courses = (res.data.list || []).reduce((pre, cur) => {
      if (!pre[cur.courseId]) {
        pre[cur.courseId] = [];
      }
      pre[cur.courseId].push(cur);
      return pre;
    }, {});
    for (let i = 0; i < Object.keys(courses).length; i++) {
      const courseId = Object.keys(courses)[i];
      state.lessonList[courseId] = courses[courseId].sort(
        (a, b) => a.sort - b.sort
      );
    }
    emit("backQuery", { ...state.lessonList });
    // proxy变量存储时需要解构
    setStore({ name: "lessonList", content: { ...state.lessonList } });
    state.pageLoading = false;
  } else {
    const res = await getLessonList({
      courseId,
      order: "desc",
      pageNum: 1,
      pageSize: 1000,
      sort: "sort",
    });
    state.lessonList[courseId] = (res.data.list || []).sort(
      (a, b) => a.sort - b.sort
    );
    emit("backQuery", { ...state.lessonList });
    setStore({ name: "lessonList", content: { ...state.lessonList } });
    state.pageLoading = false;
  }
};

onMounted(() => {
  queryLessonList();
  $bus.on("updateCourse", () => {
    queryLessonList();
  });
});
</script>

<style lang="less" scoped>
.directory {
  padding: 0 14px;
}
.lesson {
  padding: 0 14px;
}
.lesson-item {
  justify-content: flex-start;
  cursor: pointer;
  &.active,
  &:hover {
    color: #ffc300;
    font-weight: 500;
  }
  &__left {
    flex: 1;
    overflow: hidden;
  }
  .course-name {
    margin-left: 14px;
  }
  .free {
    color: #80848f;
  }
}
:deep(.ant-collapse-header) {
  font-weight: 500;
  font-size: 16px;
  background-color: #f4f4f4;
  border-radius: 8px;
  margin-bottom: 10px;
}
:deep(.ant-collapse-arrow svg) {
  transform: rotate(90deg);
}
:deep(.ant-collapse-item-active .ant-collapse-arrow svg) {
  transform: rotate(-90deg) !important;
}
</style>