import { isNotEmpty, formatDate } from "../utils/tool"

/**
 * 日期格式化
 * @param date
 * @param format
 * @returns {*}
 */
export function fmtDate (date, format) {
  if (!isNotEmpty(date)) {
    return ''
  }
  return formatDate(new Date(date), format)
}