import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory } from "vue-router";
import routes from "@/router";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import "@/css/less/style.less";
import EventBus from "@/utils/eventBus";
import directives from "./directives/index";
import * as filters from './filter'
import vConsole from 'vconsole'
import { isMobile } from "@/utils/tool";
import vue3PhotoPreview from 'vue3-photo-preview';
import 'vue3-photo-preview/dist/index.css';

const $bus = new EventBus();

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PATH),
  routes,
});

const app = createApp(App)

if (isMobile() && process.env.VUE_APP_ENV === 'test') {
  new vConsole()
}
  
  // 定义全局过滤器
app.config.globalProperties.$filters = filters;
  
app.use(router)
  .use(Antd)
  .use(directives)
  .use(vue3PhotoPreview)
  .provide("$bus", $bus)
  .mount("#app");
