/**
 * 从上传的图片中获取base64地址
 * @param {*} img
 * @param {*} callback
 */
export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

/**
 * 判断对象是否为空
 * @param obj
 * @returns {boolean}
 */
export const isNotEmpty = (obj) => {
  let flag = true;
  if (obj === "" || obj == null || obj === undefined || obj === "undefined") {
    flag = false;
  }
  return flag;
};

/**
 * 格式化时间戳
 * @param date
 * @param fmt
 * @returns {*}
 */
export const formatDate = (date, fmt) => {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  let o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : ("00" + str).substr(str.length)
      );
    }
  }
  return fmt;
};

/**
 * 判断是否为移动端
 * @returns
 */
export const isMobile = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /mobile|android|iphone|ipad|ipod|windows phone|iemobile/i.test(
    userAgent
  );
};

/**
 * 
 * @param {String} url 路径地址
 * @returns 地址参数
 */
export const getUrlQuery = (url) => {
  const params = url.split('?')[1];
  return  params ? params.split('&').reduce((pre, cur) => {
    const splitValue = cur.split("=")
    return {
      ...pre,
      [splitValue[0]]: splitValue[1]
    }
  },{}) : {}
}