<template>
  <HeaderLayout v-if="showHeader"></HeaderLayout>
  <router-view :class="[showHeader && 'padding-header']"></router-view>
  <Footer v-if="showFooter"></Footer>
</template>

<script>
import HeaderLayout from "@/components/HeaderLayout";
import Footer from "@/components/Footer";
import { getUserInfo } from "@/api/login";
import { setStore, removeStore } from "@/utils/auth";
import { isMobile } from "@/utils/tool";
export default {
  name: "App",
  components: {
    HeaderLayout,
    Footer,
  },
  watch: {
    $route: {
      handler(val) {
        const isFull = val.path !== "/video";
        this.showHeader = isFull;
        this.showFooter = isFull;
        // 移动端不展示页头和页脚
        if (isMobile()) {
          this.showHeader = false;
          this.showFooter = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeCreate() {
    const hasMobileUrl = ["/detail", "/pay", "/login", "/bargain"];
    const isNotJump = hasMobileUrl.some(
      (item) => window.location.href.indexOf(item) > -1
    );
    // 课程和支付页的移动端不跳转至mobile
    if (isMobile() && !isNotJump) {
      window.location.href = `${process.env.VUE_APP_BASE_URL}/mobile.html`;
    }
    // 判断登录是否有效
    // getUserInfo()
    //   .then((res) => {
    //     setStore({ name: "isLogin", content: true });
    //     setStore({ name: "userInfo", content: res.data });
    //   })
    //   .catch((err) => {
    //     if (err.status === 401) {
    //       setStore({ name: "isLogin", content: false });
    //       removeStore({ name: "access_token" });
    //     }
    //   });
  },
  data() {
    return {
      showHeader: true,
      showFooter: true,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #1a253b;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.padding-header {
  margin-top: 60px;
}
</style>
