<template>
  <div :class="['header-layout', state.isAboutUs && 'black']">
    <div class="header-container">
      <div class="header-logo" @click="goMyself('/home')">
        <img
          src="//static.yanzhishi.cn/images/pc/2024/logo.jpg"
          alt="logo"
        />
      </div>
      <ul class="header-menu">
        <li
          v-for="menuItem in state.menu"
          :key="menuItem.name"
          @click="showLogin(menuItem)"
        >
          <span class="header-menu__item" v-if="menuItem.path === '/login'">{{
            menuItem.name
          }}</span>
          <span
            class="header-menu__item"
            v-else-if="menuItem.path === '/personal'"
            @click="jumpPersonal(menuItem)"
          >
            <a-dropdown>
              <div>
                <img
                  class="avatar"
                  :src="state.userInfo.previewUrl"
                  alt="头像"
                />
                <span class="nickname">{{ state.userInfo.nickName }}</span>
              </div>
              <template #overlay>
                <a-menu>
                  <a-menu-item
                    v-for="my in state.personalMenu"
                    :key="my.id"
                    @click="handlePersonal(my)"
                  >
                    <span>{{ my.name }}</span>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </span>
          <router-link
            v-else
            class="header-menu__item"
            :to="menuItem.to"
            exact
            >{{ menuItem.name }}</router-link
          >
        </li>
      </ul>
    </div>
    <a-modal
      wrapClassName="login-modal"
      v-model:visible="state.visible"
      @cancel="closeLogin"
      :footer="null"
      centered
      width="380px"
    >
      <!-- <div class="inner">
        <h2>登录/注册</h2>
        <p>请使用微信扫描二维码登录</p>
        <img :src="state.qrcodeImg" alt="扫码" />
        <p class="tips">注：扫描将自动为新用户创建账号</p>
      </div> -->
      <iframe
        :src="state.loginUrl"
        frameborder="0"
        width="380"
        height="320"
      ></iframe>
    </a-modal>
  </div>
</template>
<script setup>
// import { TOP_MENU } from "@/utils/const";
import { reactive, watch, onUnmounted, inject, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import routes from "@/router";
import { getStore } from "@/utils/auth";
import { getLoginImg, scanLoginResult } from "@/api/login";
import { setToken, setRefreshToken, setStore, removeStore } from "@/utils/auth";
import { getUserInfo } from "@/api/login";
import { getUrlQuery } from "@/utils/tool";
const $bus = inject("$bus");

const route = useRoute();
const router = useRouter();

const state = reactive({
  menu: [],
  visible: false,
  qrcodeImg: "",
  timer: null,
  isAboutUs: false,
  userInfo: {
    nickName: "",
    previewUrl: "",
  },
  personalMenu: [
    {
      id: 1,
      name: "个人中心",
    },
    {
      id: 2,
      name: "退出",
    },
  ],
  loginUrl: "",
});

watch(route, () => {
  refreshLoginStatus();
});

const refreshLoginStatus = () => {
  getUserInfo()
    .then((res) => {
      state.userInfo = res.data;
      setStore({ name: "userInfo", content: res.data });
      setStore({ name: "isLogin", content: true });
    })
    .catch((err) => {
      if (err.status === 401) {
        setStore({ name: "isLogin", content: false });
        removeStore({ name: "access_token" });
      }
    });
  state.isAboutUs = route.path === "/aboutus";
  const isLogin = getStore({ name: "isLogin" }) || false;
  state.menu = routes
    .filter((item) => {
      if (item.isLogin !== undefined) {
        return item.show && item.isLogin === isLogin;
      }
      return item.show;
    })
    .map((item) => {
      if (item.path === "/login") {
        return {
          ...item,
          to: {
            path: item.path,
            query: {
              redirect: "/home",
            },
          },
        };
      }
      return {
        ...item,
        to: {
          path: item.path,
        },
      };
    });
};
const closeLogin = () => {
  if (state.timer) clearInterval(state.timer);
  state.visible = false;
};
const jumpPersonal = (menuItem) => {
  router.push({
    path: menuItem.path,
  });
};
const handlePersonal = (personalMenu) => {
  switch (personalMenu.id) {
    case 1:
      goMyself("/personal");
      break;
    case 2:
      logout();
      break;
    default:
      goMyself();
  }
};
// 点击个人中心
const goMyself = (path) => {
  router.push({
    path,
  });
};
// 点击退出
const logout = () => {
  // 退出，删除sessionStorage中的存储
  removeStore({ name: "isLogin" });
  removeStore({ name: "access_token" });
  removeStore({ name: "course" });
  removeStore({ name: "lessonList" });
  refreshLoginStatus();
  router.push({
    path: "/home",
  });
};

const setWxLoginUrl = () => {
  const redirectUrl = window.location.href.replace(
    "www.yanzhishi.cn",
    "yanzhishi.cn"
  );
  // 自定义微信官网扫码登录样式
  const customStyle = window.btoa(
    `
    .impowerBox .qrcode { width: 160px; margin-top: 30px; }
    .impowerBox .title { font-size: 22px; font-weight: 600; }
    .impowerBox .status p { font-size: 16px; }
    `
  );
  const appIdKeys = {
    test: "wx8cf78727280432b8",
    production: "wx404a2f5123097724",
  };
  return `https://open.weixin.qq.com/connect/qrconnect?appid=${
    appIdKeys[process.env.VUE_APP_ENV]
  }&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_login&href=data:text/css;base64,${customStyle}&state=STATE#wechat_redirect`;
};

const showLogin = async (menuItem) => {
  if (menuItem.path !== "/login") return;
  state.loginUrl = setWxLoginUrl();
  state.visible = true;
  // const response = await getLoginImg();
  // state.qrcodeImg = `data:image/jpg;base64,${response.data.encodeImg}`;
  // state.timer = setInterval(() => {
  //   scanLoginResult({
  //     grant_type: "wxScanLogin",
  //     code: response.data.code,
  //   })
  //     .then((res) => {
  //       if (res.code !== 600) {
  //         clearInterval(state.timer);
  //         state.timer = null;
  //         setToken(res.access_token);
  //         setRefreshToken(res.refresh_token);
  //         setStore({ name: "isLogin", content: true });
  //         state.visible = false;
  //         refreshLoginStatus();
  //         $bus.emit("updateCourse");
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.status !== 600) {
  //         clearInterval(state.timer);
  //         state.timer = null;
  //       }
  //     });
  // }, 2000);
  // state.visible = true;
};

const getWxLoginToken = (code) => {
  scanLoginResult({
    grant_type: "wxScanLogin",
    code: code,
  }).then((res) => {
    setToken(res.access_token);
    setRefreshToken(res.refresh_token);
    setStore({ name: "isLogin", content: true });
    refreshLoginStatus();
    $bus.emit("updateCourse");
  });
};

onMounted(() => {
  $bus.on("updateUser", () => {
    refreshLoginStatus();
  });
  $bus.on("showLogin", () => {
    showLogin({
      path: "/login",
    });
  });
  // 如果是微信登录重定向后的地址
  const urlParmas = getUrlQuery(window.location.href);
  if (urlParmas.state === "STATE" && urlParmas.code) {
    getWxLoginToken(urlParmas.code);
  }
});

onUnmounted(() => {
  if (state.timer) clearInterval(state.timer);
});
</script>
<style lang="less" scoped>
.router-link-active {
  display: inline-block;
  color: #ffc300 !important;
  line-height: 40px;
  font-weight: 600;
  border-bottom: 2px solid #ffc300 !important;
}
.header-layout {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  flex-shrink: 0;
  border-bottom: 1px solid #f1f1f1;
  &.black {
    background: #242932;
    border-bottom: none;
    .header-container {
      .header-menu__item {
        color: #fff;
      }
    }
  }
  .header-container {
    width: 1120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-logo {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 280px;
      }
    }
    .header-menu {
      display: flex;
      align-items: center;
      margin: 0;
      &__item {
        display: inline-block;
        margin: 0 1rem;
        padding: 0 0.5rem;
        line-height: 60px;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        &:hover {
          font-weight: 600;
          color: #fbce22;
        }
      }
      .active {
        border-bottom: 2px solid #fbce22;
        a {
          color: #fbce22;
        }
      }
      a {
        color: #1a253b;
      }
    }
  }
}
.login-modal {
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      text-align: center;
      color: #ffc300;
      font-size: 22px;
      font-weight: 500;
    }
    p {
      text-align: center;
      font-size: 16px;
      margin: 0;
    }
    img {
      width: 200px;
      margin: 14px 0;
    }
    .tips {
      font-size: 14px;
      color: #666;
    }
  }
}
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #f1f1f1;
  margin-right: 6px;
}
.nickname {
  font-weight: 500;
}
</style>

<style lang="less">
.login-modal {
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-body {
    padding: 0;
    padding-top: 20px;
  }
}
</style>
